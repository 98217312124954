import axios from '@axios'

const PREFIX = 'declaracao-dctf-web'

export default {

  getPaginado(page, pageSize, filtros) {
    return axios.post(`${PREFIX}/paged?pagina=${page}&tamanhoPagina=${pageSize}`, filtros)
  },

  getDctfWebsEmpresa(id) {
    return axios.post(`${PREFIX}/${id}/dctf-web-empresa`)
  },

  getURLDctfWeb(id, isDarf) {
    return axios.get(`${PREFIX}/${id}/url?isDarf=${isDarf}`)
  },

  notificaDctfWeb(nomeEmpresa, emailEmpresa, emailCc, id, mensagemAdicional, definirEmailPadrao) {
    return axios.post(`${PREFIX}/${id}/notifica?emailEmpresa=${emailEmpresa}&emailCc=${emailCc}&nomeEmpresa=${nomeEmpresa}&mensagemAdicional=${encodeURIComponent(mensagemAdicional)}&definirEmailPadrao=${definirEmailPadrao}`)
  },

  baixaEmLote(filtros, nomeArquivo, tipoDownload) {
    return axios.post(`${PREFIX}/baixa-lotes?nomeArquivo=${nomeArquivo}&tipoDownload=${tipoDownload}`, filtros)
  },

  atualizarManual(empresasFiltro) {
    return axios.post(`${PREFIX}/atualizar`, empresasFiltro)
  },

  getConsumoCredito(empresasFiltro) {
    return axios.post(`${PREFIX}/credito/consumo`, empresasFiltro)
  },

  getEmpresasAtualizacaoEmLote() {
    return axios.get(`${PREFIX}/empresas`)
  },

  getEmpresasAtualizacaoEmLoteFiltrado(body) {
    return axios.post(`${PREFIX}/empresas`, body)
  },
}
